* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins';
}

body {
    touch-action: manipulation;
}

#portal-root {
    overflow: scroll;
}

td {
    border: 1px solid #000;
    text-align: center;
}
